//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//


$amfile-line__margin-bottom: 1rem;
$amfile-line__height: 30px;


.am-attachments {
    .am-fileline {
        margin-bottom: $amfile-line__margin-bottom;

        .am-fileicon {
            margin-right: 10px;
            max-height: $amfile-line__height;
            vertical-align: middle;
        }

        .am-filelink {
            line-height: $amfile-line__height;
            height: $amfile-line__height;
        }

        .am-filesize {
            font-size: 90%;
        }
    }
}


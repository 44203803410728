/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.am-attachments .am-fileline {
  margin-bottom: 1rem;
}

.am-attachments .am-fileline .am-fileicon {
  margin-right: 10px;
  max-height: 30px;
  vertical-align: middle;
}

.am-attachments .am-fileline .am-filelink {
  line-height: 30px;
  height: 30px;
}

.am-attachments .am-fileline .am-filesize {
  font-size: 90%;
}
